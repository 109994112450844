import cookieStore from './cookie';

const WEB_VIEW_RULES = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native, but it will contain "wv"
  // Android KitKat to lollipop webview will put Version/_X.X_ string (@see: https://developer.chrome.com/docs/multidevice/user-agent/)
  'Android.*(wv|Version\/\d+.\d+)',
  // old chrome android webview agent
  'Linux; U; Android'
];

const MOB_COOKIE_NAME = 'pb-mob-app';

const webviewRegExp = new RegExp('(' + WEB_VIEW_RULES.join('|') + ')', 'ig');

const getCookieData = () => {
  return JSON.parse(cookieStore.get(MOB_COOKIE_NAME));
};

// @TODO remove this method if we do not use anymore
export const isWebViewUA = (ua) => {
  return !!ua.match(webviewRegExp);
};

// Better to use this method for detect WebView
export const isWebView = () => {
  return !!cookieStore.get(MOB_COOKIE_NAME);
};

export const getMobAppVersion = () => {
  if (!isWebView()) {
    return null;
  }

  const monAppCookieData = getCookieData();

  return monAppCookieData._appVersion;
};

export const isPaybisAndroidApp = () => {
  if (!isWebView()) {
    return false;
  }

  const monAppCookieData = getCookieData();

  return monAppCookieData._osName === 'Android';
};

export const isPaybisAppleApp = () => {
  if (!isWebView()) {
    return false;
  }

  const monAppCookieData = getCookieData();

  return monAppCookieData._osName === 'iOS';
};

let isFlutterReady = false;
const flutterEventQueue = [];
const sendFlutterEvent = (flutterEvent) => {
  const flutterWebView = window.flutter_inappwebview;

  if (!flutterWebView || !flutterWebView.callHandler) {
    return;
  }
  
  flutterWebView.callHandler("WebViewJSHandler", {
    action: flutterEvent.eventName,
    data: flutterEvent.data,
  });
};
export const emitMobAppEvent = (eventName, data = {}) => {
  if (!isWebView()) return;

  if (isFlutterReady) {
    sendFlutterEvent({ eventName, data });
  } else {
    flutterEventQueue.push({ eventName, data });
  }
};

if (typeof window !== 'undefined') {
  window.addEventListener(
    "flutterInAppWebViewPlatformReady",
    () => {
      isFlutterReady = true;
      
      flutterEventQueue.forEach((flutterEvent) => sendFlutterEvent(flutterEvent));
    },
    { once: true }
  );
}

